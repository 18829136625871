<template>
  <v-container>
    <ValidationObserver ref="observer" @submit.prevent="submitForm" tag="form">
      <v-card>
        <v-card-title>Form details</v-card-title>
        <v-card-text>
          <ValidationProvider rules="required" name="Value" v-slot="{ errors, valid }">
            <v-text-field v-model="form.name" :counter="50" label="Name" :success="valid" required :error-messages="errors"></v-text-field>
          </ValidationProvider>
          <div class="d-flex justify-space-between align-center">
            <div>Questions</div>
            <v-btn icon color="primary text--darken-1" @click="addNewQuestion">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <v-list dense nav>
            <v-list-item v-for="(question, i) in form.questions" :key="`question-${i}`" dense>
              <v-list-item-content>
                <v-list-item-title v-text="question.value"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="flex-row">
                <v-btn icon color="secondary" @click="editQuestion(i)" small>
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn icon color="red" @click="deleteQuestion(i)" small>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn type="submit" color="primary">
            <v-icon>mdi-save</v-icon>Save form
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
    <QuestionDetailsDialog
      :isVisible="showQuestionDetailsDialog"
      :selectedQuestion="selectedQuestion"
      @closed="showQuestionDetailsDialog = false"
      @save="addQuestion"
    />
  </v-container>
</template>

<script>
import { saveForm } from '@/api/backend.js'
import QuestionDetailsDialog from '@/components/QuestionDetailsDialog.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'FormDetails',
  components: {
    QuestionDetailsDialog,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      valid: false,
      loading: false,
      selectedQuestionIndex: null,
      selectedQuestion: null,
      showQuestionDetailsDialog: false,
      form: {
        id: null,
        name: null,
        questions: []
      }
    }
  },
  mounted() {
    console.log(this.$route.params.form)
    if (this.$route.params !== null && this.$route.params.form !== undefined) {
      this.form = this.$route.params.form
    }
  },
  methods: {
    submitForm() {
      if (this.form.questions.length === 0) {
        this.showErrorNotification('Please add at least one question to this form')
        return
      }
      this.loading = true
      saveForm(this.form).then(response => {
        this.showSuccessNotification('Form saved successfully')
        this.showConfirmationDialog('Do you want to preview the form?').then(r => {
          this.$router.push({ name: 'FormView', params: { id: response.data.id } })
        }).catch(e => this.$router.push({ name: 'Home' }))
      }).catch(e => this.showErrorNotification('An error occured, try again later'))
        .then(e => { this.loading = false })
    },
    editQuestion(index) {
      this.selectedQuestionIndex = index
      this.selectedQuestion = this.form.questions[index]
      this.showQuestionDetailsDialog = true
    },
    deleteQuestion(index) {
      this.form.questions.splice(index, 1)
    },
    addQuestion(question) {
      if (this.selectedQuestionIndex !== null) {
        this.form.questions[this.selectedQuestionIndex] = question
      } else {
        this.form.questions.push(question)
      }
      this.selectedQuestionIndex = null
      this.selectedQuestion = null
    },
    addNewQuestion() {
      this.showQuestionDetailsDialog = true
      this.selectedQuestionIndex = null
      this.selectedQuestion = null
    }
  }
}
</script>
<style lang="scss">

</style>
