<template>
  <v-dialog v-model="visible" width="500" persistent :fullscreen="fullscreen" scrollable>
    <ValidationObserver ref="observer" @submit.prevent="saveQuestion" tag="form">
      <v-card :loading="loading">
        <v-card-title class="justify-space-between">
          <div>
            <div>Question details</div>
          </div>
          <div>
            <v-btn icon small @click="fullscreen = !fullscreen">
              <v-icon v-if="!fullscreen">mdi-window-maximize</v-icon>
              <v-icon v-if="fullscreen">mdi-window-restore</v-icon>
            </v-btn>
            <v-btn icon small @click="visible = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text class="pt-5">
          <div class="row">
            <div class="col-12">
              <ValidationProvider rules="required" name="Value" v-slot="{ errors, valid }">
                <v-textarea v-model="questionValue" rows="3" label="Value" :success="valid" required :error-messages="errors" dense auto-grow clearable></v-textarea>
              </ValidationProvider>
            </div>
            <div class="col-12 col-md-6">
              <ValidationProvider rules="required" name="Question type" v-slot="{ errors, valid }">
                <v-select v-model="selectedQuestionType" :items="availableQuestionTypes" hide-details item-value="value" item-text="text" label="Question type" :success="valid" required :error-messages="errors" dense>
                </v-select>
              </ValidationProvider>
            </div>
            <div class="col-12 col-md-6">
              <ValidationProvider rules="required" name="Chart type" v-slot="{ errors, valid }">
                <v-select v-model="selectedChartType" :items="availableChartTypeByQuestionTypes" hide-details item-value="value" item-text="text" label="Chart type" :success="valid" required :error-messages="errors" dense>
                </v-select>
              </ValidationProvider>
            </div>
          </div>
          <div v-if="showAnswerOptions" class="pt-5">
            <div class="d-flex justify-space-between align-center">
              <div>Answer options</div>
              <v-btn icon color="primary" @click="addAnswerOption">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
            <v-list dense nav>
              <v-list-item v-for="(option, i) in answerOptions" :key="`option-${i}`" dense>
                <v-list-item-content>
                  <v-list-item-title v-text="option.value"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="flex-row">
                  <v-btn icon color="secondary" @click="editAnswerOption(i)" small>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteAnswerOption(i)" small>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="red" @click="visible = false">Cancel</v-btn>
          <v-btn plain color="green" type="submit">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'QuestionDetailsDialog',
  props: {
    isVisible: Boolean,
    selectedQuestion: Object
  },
  components: { ValidationObserver, ValidationProvider },
  computed: {
    visible: {
      get: function() {
        return this.isVisible
      },
      set: function(v) {
        this.$emit('closed')
      }
    },
    showAnswerOptions() {
      return this.selectedQuestionType !== null &&
        this.selectedChartType !== null &&
        this.questionValue !== null &&
        this.questionValue.trim().length > 0 &&
        this.selectedQuestionType !== 'SINGLE_WORD' &&
        this.selectedQuestionType !== 'FREE_TEXT' &&
        this.selectedQuestionType !== 'SINGLE_NUMBER'
    },
    availableChartTypeByQuestionTypes() {
      if (this.selectedQuestionType === 'SINGLE_WORD') {
        return [{ text: 'Word cloud', value: 'WORDCLOUD' }]
      } else if (this.selectedQuestionType === 'FREE_TEXT') {
        return [{ text: 'None', value: 'NONE' }]
      } else if (this.selectedQuestionType === 'SINGLE_NUMBER') {
        return [{ text: 'Average', value: 'AVERAGE' }]
      } else {
        return this.availableChartTypes
      }
    }
  },
  data: function() {
    return {
      loading: false,
      fullscreen: false,
      availableQuestionTypes: [
        { text: 'Single selection', value: 'SINGLE_SELECTION' },
        { text: 'Multiple selection', value: 'MULTIPLE_SELECTION' },
        { text: 'Single word', value: 'SINGLE_WORD' },
        { text: 'Free text', value: 'FREE_TEXT' },
        { text: 'Single number', value: 'SINGLE_NUMBER' }
      ],
      availableChartTypes: [
        { text: 'Bar', value: 'BAR' },
        { text: 'Donut', value: 'DONUT' },
        { text: 'Pie', value: 'PIE' },
        { text: 'Average', value: 'AVERAGE' }
      ],
      uuid: null,
      selectedQuestionType: null,
      selectedChartType: null,
      questionValue: null,
      answerOptions: []
    }
  },
  methods: {
    addAnswerOption() {
      this.showTextPromptDialog('Answer details', 'Answer value', 'required').then(r => {
        this.answerOptions.push({
          value: r
        })
      })
    },
    deleteAnswerOption(index) {
      this.answerOptions.splice(index, 1)
    },
    editAnswerOption(index) {
      const option = this.answerOptions[index]
      this.showTextPromptDialog('Answer details', 'Answer value', 'required', option.value).then(r => {
        option.value = r
      })
    },
    saveQuestion() {
      if (this.answerOptions.length === 0 &&
        this.selectedQuestionType !== 'SINGLE_WORD' &&
        this.selectedQuestionType !== 'FREE_TEXT' &&
        this.selectedQuestionType !== 'SINGLE_NUMBER') {
        this.showErrorNotification('Please add at least one answer option')
        return
      }
      const question = {
        type: this.selectedQuestionType,
        chartType: this.selectedChartType,
        value: this.questionValue,
        options: this.answerOptions
      }
      if (this.uuid !== null) {
        question.uuid = this.uuid
      }
      this.$emit('save', question)
      this.visible = false
    }
  },
  watch: {
    isVisible: function(newValue, oldValue) {
      if (newValue) {
        if (this.$refs.observer) {
          this.$refs.observer.reset()
        }
        this.selectedQuestionType = null
        this.selectedChartType = null
        this.questionValue = null
        this.answerOptions = []
        this.uuid = null
        if (this.selectedQuestion !== null) {
          this.uuid = this.selectedQuestion.uuid
          this.selectedQuestionType = this.selectedQuestion.type
          this.selectedChartType = this.selectedQuestion.chartType
          this.questionValue = this.selectedQuestion.value
          this.answerOptions = this.clone(this.selectedQuestion.options)
        }
      }
    }
  }

}
</script>
