var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":"","fullscreen":_vm.fullscreen,"scrollable":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveQuestion.apply(null, arguments)}}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"justify-space-between"},[_c('div',[_c('div',[_vm._v("Question details")])]),_c('div',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[(!_vm.fullscreen)?_c('v-icon',[_vm._v("mdi-window-maximize")]):_vm._e(),(_vm.fullscreen)?_c('v-icon',[_vm._v("mdi-window-restore")]):_vm._e()],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-text',{staticClass:"pt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"rows":"3","label":"Value","success":valid,"required":"","error-messages":errors,"dense":"","auto-grow":"","clearable":""},model:{value:(_vm.questionValue),callback:function ($$v) {_vm.questionValue=$$v},expression:"questionValue"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Question type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.availableQuestionTypes,"hide-details":"","item-value":"value","item-text":"text","label":"Question type","success":valid,"required":"","error-messages":errors,"dense":""},model:{value:(_vm.selectedQuestionType),callback:function ($$v) {_vm.selectedQuestionType=$$v},expression:"selectedQuestionType"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Chart type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.availableChartTypeByQuestionTypes,"hide-details":"","item-value":"value","item-text":"text","label":"Chart type","success":valid,"required":"","error-messages":errors,"dense":""},model:{value:(_vm.selectedChartType),callback:function ($$v) {_vm.selectedChartType=$$v},expression:"selectedChartType"}})]}}])})],1)]),(_vm.showAnswerOptions)?_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v("Answer options")]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.addAnswerOption}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.answerOptions),function(option,i){return _c('v-list-item',{key:("option-" + i),attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(option.value)}})],1),_c('v-list-item-action',{staticClass:"flex-row"},[_c('v-btn',{attrs:{"icon":"","color":"secondary","small":""},on:{"click":function($event){return _vm.editAnswerOption(i)}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){return _vm.deleteAnswerOption(i)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1)}),1)],1):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"plain":"","color":"red"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"plain":"","color":"green","type":"submit"}},[_vm._v("Save")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }